import React from "react";
import { useSearchParams } from "react-router-dom";
import { generateClassName } from "../../hooks/useAttributes";
import { IAuthorizationOptions } from "../../hooks/useAuthorized";
import Tab from "./Tab";
import "./TabSwitcher.css";

export interface ITabData extends IAuthorizationOptions {
  label?: string,
  data: string,
  first?: boolean,
  priority?: number,
  hidden?: boolean,
  showLabelOnlyOnActive?: boolean,
  icon?: string,
  iconSize?: number,
  iconTooltip?: string,
  foreColor?: string,
  backColor?: string
}

export interface ITabHighlightData {
  left: number,
  width: number,
  height: number
}

export type TabSwitcherSize = "small" | "regular" | "tiny";

export type TabSwitcherVariant = "muted" | "standard" | "icons";

interface ITabSwitcherProps {
  tabs: Array<ITabData>,
  primaryPriority?: number,
  variant?: TabSwitcherVariant,
  size?: TabSwitcherSize,
  className?: string,
  gap?: 0 | 1 | 2 | 3 | 4 | 5,
  defaultIconSize?: number,
  align?: "start" | "end",
  removeOnUnmount?: boolean,
  displayedPriority?: number,
  tabQueryParamKey?: string,
  showIfOnlyOne?: boolean,
  initialData?: string,
  saveActiveTab?: (data: string) => void
}

export default function TabSwitcher({ saveActiveTab, initialData, showIfOnlyOne, removeOnUnmount = false, defaultIconSize, size = "regular", gap, align, tabs, displayedPriority, primaryPriority, variant = "standard", tabQueryParamKey, className }: ITabSwitcherProps) {
  const [currentData, setCurrentData] = React.useState<string>(initialData ?? "");
  const [query, setQuery] = useSearchParams();

  const switcherRef = React.useRef<HTMLDivElement>(null);

  const getGap = () => {
    if (gap) return gap;

    switch (size) {
      case "small": return 2;
      case "regular": return 3;
      case "tiny": return 2;
    }
  }

  const getFirstTab = (): ITabData | undefined => {
    if (!tabs?.length) return;
    const usableTabs = tabs.filter(t => !t.hidden);
    if (!usableTabs?.length) return;
    const firstTab = usableTabs.find(t => t.first);
    if (firstTab) return firstTab;
    if (!initialData) return usableTabs[0];
    return usableTabs.find(t => t.data === initialData) ?? usableTabs[0];
  }

  React.useEffect(() => {
    if (!tabQueryParamKey) return;

    return () => {
      if (!removeOnUnmount) return;
      const newQuery = new URLSearchParams(query);
      newQuery.delete(tabQueryParamKey);

      setQuery(newQuery, { replace: true });
    }
  }, [])

  React.useEffect(() => {
    if (!tabs?.length) return;
    if (!tabQueryParamKey) return;
    
    const firstTab = getFirstTab();
    
    if (!firstTab) return;
    if (primaryPriority !== undefined && firstTab.priority !== primaryPriority) return;
    
    if (!query) {
      switchTabs(firstTab);
      return;
    }
    
    const data = query.get(tabQueryParamKey);

    if (!data) {
      switchTabs(firstTab);
      return;
    }

    const tab = tabs.find(t => t.data === data);

    if (tab) switchTabs(tab);
    else switchTabs(firstTab);

  }, [tabs, query, initialData]);

  if (!tabs) return null;

  const switchTabs = (tab: ITabData) => {

    setCurrentData(tab.data);

    if (saveActiveTab) saveActiveTab(tab.data);
    if (!tabQueryParamKey) return;

    const currentQuery = query.get(tabQueryParamKey);

    if (currentQuery === tab.data) return;

    const newQuery = new URLSearchParams(query);
    newQuery.set(tabQueryParamKey, tab.data);

    setQuery(newQuery, { replace: true });
  }

  const visibleTabs = (tabs?.length) ? tabs.filter(t => !t.hidden) : [];

  const tabSwitcherClassName = generateClassName(className, "position-relative tab-switcher d-flex flex-row align-items-center", {
    value: size,
    base: "tab-switcher-"
  }, {
    value: align,
    base: "justify-content-"
  }, {
    value: getGap(),
    base: "gap-"
  });

  if (!visibleTabs?.length || visibleTabs.length <= (showIfOnlyOne ? 0 : 1)) return null;

  return (
    <div className={tabSwitcherClassName} ref={switcherRef} style={{ overflowX: "auto", overflowY: "hidden" }}>
      {
        tabs.map((t, index) => (
          (displayedPriority === undefined || t.priority === displayedPriority) && (
            <Tab
              size={size}
              key={`tab-switcher-tab-${t}-${index}`}
              content={t}
              defaultIconSize={defaultIconSize}
              variant={variant}
              currentData={currentData}
              setActiveTab={switchTabs}
            />
          )))
      }
    </div>
  )

}