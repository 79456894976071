import React from "react";
import { mailTemplatesCreate, mailTemplatesUpdate } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import { useMailTemplates } from "../../state/api/templates/useMailTemplates";
import { IMailTemplate, IMailTemplateDocument } from "../../types/ApiTypes";
import { IGlobalMailTemplate, MailTemplate } from "../../types/globalMailTemplates.schema";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import DeleteMailTemplateButton from "./DeleteMailTemplateButton";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import TemplateElementEdit from "./TemplateElementEdit";
import TemplatePlaceholderChip from "./TemplatePlaceholderChip";
import TemplatePreview from "./TemplatePreview";

export interface IUpdateMailTemplateButtonProps {
  isCreate?: boolean,
  existingTemplate?: IMailTemplate,
  templateType?: MailTemplate,
  openButton?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  baseTemplate?: IGlobalMailTemplate,
  disabled?: boolean
}

export enum UpdateMailTemplateTab {
  subject = "subject",
  body = "body",
}

export default function UpdateMailTemplateButton({ existingTemplate, templateType, disabled, openButton, baseTemplate, isCreate }: IUpdateMailTemplateButtonProps) {

  const [tab, setTab] = React.useState<UpdateMailTemplateTab>(UpdateMailTemplateTab.subject);

  const initialBaseTemplate = existingTemplate?.baseTemplate || baseTemplate || undefined;

  const { getDisplayNameForMailTemplate } = useTemplateUtil();
  const { reloadMailTemplates } = useMailTemplates();

  const callApi = useApi();

  const toggleButton = openButton || <Button text={isCreate ? "Neue Vorlage" : "Vorlage bearbeiten"} variant={isCreate ? "text" : "solid"} icon={isCreate ? "plus" : "pen"} />;

  if (!isCreate && !existingTemplate) return null;

  return (
    <WithPermissions permissions={["templates.mails.update"]}>

      <ModalForm
        disabled={disabled}
        initialValues={{
          body: existingTemplate ? existingTemplate.body : (initialBaseTemplate?.body || []),
          subject: existingTemplate ? existingTemplate.subject : (initialBaseTemplate?.subject || []),
          templateType: templateType,
          baseTemplate: initialBaseTemplate
        } as IMailTemplateDocument}
        enableReinitialize
        button={toggleButton}
        title={isCreate ? (baseTemplate ? `Neue Version von '${getDisplayNameForMailTemplate(baseTemplate.templateType)}'` : "Neues Template") : "Template bearbeiten"}
        onSubmit={async (values) => {
          const res = isCreate
            ? await callApi(mailTemplatesCreate(values))
            : await callApi(mailTemplatesUpdate({ ...values, id: existingTemplate!._id }));

          if (!res) return false;

          await reloadMailTemplates();
          return true;
        }}
        sidebar={
          (formik, close) => (
            <Flex fullHeight fullWidth justify="between">
              <Flex fullWidth>
                <TemplatePreview content={formik.values.subject} label="Betreff" />
                <TemplatePreview content={formik.values.body} label="Inhalt" />
              </Flex>
              <Flex fullWidth gap={3}>
                {
                  initialBaseTemplate && (
                    <Flex row wrap className="w-100">
                      <Typography>Verfügbare Platzhalter aus Vorlage</Typography>
                      {
                        initialBaseTemplate.availablePlaceholders && !!initialBaseTemplate.availablePlaceholders.length
                          ? initialBaseTemplate.availablePlaceholders.map(p => <TemplatePlaceholderChip placeholder={p} />)
                          : <em>Keine Platzhalter für diese Vorlage verfügbar</em>
                      }
                    </Flex>
                  )
                }
                {
                  (formik.dirty && !initialBaseTemplate)
                    ? <Button color="muted" variant="text" text="Zurücksetzen" icon="arrow-clockwise" onClick={async () => formik.resetForm()} />
                    : <div></div>
                }
                <Flex row wrap justify="between" className="w-100">
                  {
                    existingTemplate && <DeleteMailTemplateButton templateId={existingTemplate._id} afterDelete={() => close()} />
                  }
                  <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />
                </Flex>
              </Flex>
            </Flex>
          )
        }
      >
        {
          formik => (
            <Flex className="w-100" gap={4} >
              {
                initialBaseTemplate && (
                  <Flex fullWidth>
                    <Typography>Ausgewählte Basisvorlage</Typography>
                    <MailTemplateTypeBanner template={initialBaseTemplate.templateType} />
                    <Typography wrap size={12} >Bitte achten Sie darauf, nur die verfügbaren Platzhalter zu verwenden. Wenn Sie einen Platzhalter in einem Textfeld ausgewählt haben und dieser nicht grün in der darunterliegenden Leiste aufleuchtet, ist dieser nicht verfügbar.</Typography>
                  </Flex>
                )
              }
              <TemplateElementEdit
                name={tab === UpdateMailTemplateTab.subject ? "subject" : "body"}
                label={(
                  <TabSwitcher
                    tabQueryParamKey="content"
                    tabs={[
                      { data: UpdateMailTemplateTab.subject, label: "Betreff" },
                      { data: UpdateMailTemplateTab.body, label: "Inhalt" }
                    ]}
                    saveActiveTab={setTab as any}
                  />
                )}
                availablePlaceholders={initialBaseTemplate ? initialBaseTemplate.availablePlaceholders : undefined}
              />
            </Flex>
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}
