import React, { useEffect } from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import Typography from "../text/Typography";
import "./Tooltip.css";
import Flex from "../container/Flex";

export interface IWithTooltip {
  tooltip?: string,
  tooltipDelay?: number,
  tooltipTitle?: string,
  tooltipAction?: (React.ReactNode | ((closeTooltip: () => void) => React.ReactNode))
}

export interface ITooltipProps extends IWithTooltip {
  children: React.ReactNode,
  className?: string
}

export interface IIconTooltipOrigin {
  top: number,
  bottom: number,
  right: number,
  left: number,
}

export default function Tooltip(props: ITooltipProps) {

  const {
    children,
    tooltipAction,
    tooltipDelay,
    className,
    tooltip,
    tooltipTitle
  } = props;

  const [originIsTop, setOriginIsTop] = React.useState<boolean>(false);
  const [originIsLeft, setOriginIsLeft] = React.useState<boolean>(false);

  const [disappearing, setDisappearing] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<boolean>(false);
  const [tooltipOrigin, setTooltipOrigin] = React.useState<IIconTooltipOrigin>();

  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const showTimeout = React.useRef<any>();
  const hoverTimeout = React.useRef<any>();

  useEffect(() => {
    return () => {
      clearTimeout(showTimeout.current);
      clearTimeout(hoverTimeout.current);
    }
  }, []);

  const getTooltipOrigin = () => {
    if (!tooltipRef.current) return;

    const viewportDims = {
      x: (document?.documentElement?.clientWidth ?? 0),
      y: (document?.documentElement?.clientHeight ?? 0)
    }

    const viewportCenter = {
      x: viewportDims.x / 2,
      y: viewportDims.y / 2
    }

    const rect = tooltipRef.current.getBoundingClientRect();

    setTooltipOrigin({
      bottom: viewportDims.y - rect.bottom + 5 + rect.height,
      left: rect.left - rect.width,
      right: viewportDims.x - rect.right - rect.width,
      top: rect.top + rect.height + 5,
    });

    setOriginIsLeft(rect.left < viewportCenter.x);
    setOriginIsTop(rect.top < viewportCenter.y);
  }

  const toggleHover = (isEntry: boolean = false) => {
    if (!tooltip) return;

    setDisappearing(!isEntry);

    if (isEntry) {
      getTooltipOrigin();
      clearTimeout(hoverTimeout.current);
      showTimeout.current = setTimeout(() => setHover(true), tooltipDelay ?? 500);
    }
    else {
      clearTimeout(showTimeout.current);
      hoverTimeout.current = setTimeout(() => setHover(false), 200);
    }
  }

  const tooltipClass = generateClassName("tooltip-container d-flex flex-column align-items-end justify-content-start gap-3", {
    value: !!tooltipAction || !!tooltipTitle,
    onTrue: "p-3",
    standard: "p-1 ps-2 pe-2"
  });

  const wrapperClass = generateClassName(className, "position-relative tooltip-wrapper");

  return (
    <div
      ref={tooltipRef}
      className={wrapperClass}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      {children}
      {
        hover && tooltip && tooltipOrigin && (
          <div
            className={tooltipClass}
            style={generateStyle({
              name: "left",
              value: tooltipOrigin.left - 30,
              applyCondition: originIsLeft,
              unit: "px"
            }, {
              name: "top",
              value: tooltipOrigin.top + 5,
              applyCondition: originIsTop,
              unit: "px"
            }, {
              name: "bottom",
              value: tooltipOrigin.bottom + 5,
              applyCondition: !originIsTop,
              unit: "px"
            }, {
              name: "right",
              value: tooltipOrigin.right + 5,
              applyCondition: !originIsLeft,
              unit: "px"
            })}
          >
            <Flex fullWidth gap={0}>
              {tooltipTitle && <Typography size={12} bold>{tooltipTitle}</Typography>}
              <Typography size={12} wrap>{tooltip}</Typography>
            </Flex>
            {
              !!tooltipAction && (
                typeof tooltipAction === "function"
                  ? tooltipAction(() => setHover(false))
                  : tooltipAction
              )
            }
          </div>
        )
      }
    </div>
  )
}