import React from "react";
import { ApiCall } from "../../api/Api";
import { IRunDatevImportRequest } from "../../api/requests/datev/datev.requests";
import useApi from "../../hooks/useApi";
import { DatevEndpoint } from "../../types/datev.import.types";
import Button, { IButtonProps } from "../buttons/Button";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import DatevEndpointPill from "./DatevEndpointPill";
import { useLatestClientImportLog } from "../../state/api/clients/useLatestClientImportLog";
import { useDatevImports } from "../../state/api/datevImport/useDatevImports";

export interface IRunDatevImportButtonProps extends IButtonProps {
  id?: string,
  title: string,
  hideLimits?: boolean,
  apiCall: ApiCall<IRunDatevImportRequest>
}

export default function RunDatevImportButton(props: IRunDatevImportButtonProps) {
  const {
    apiCall,
    hideLimits,
    id,
    title
  } = props;

  const {
    reloadDatevImports
  } = useDatevImports({ enabled: false });

  const {
    reloadLatestClientImportLog
  } = useLatestClientImportLog({ enabled: false });

  const callApi = useApi();

  return (
    <ModalForm
      initialValues={{
        id,
        limitTo: []
      } as IRunDatevImportRequest}
      button={<Button text={title} disabled={props.disabled ?? !id} {...props} />}
      title={title}
      onSubmit={async (values) => {
        if (!id) return false;
        const res = await callApi(apiCall(values));

        if (!res?.success) return false;

        await reloadDatevImports();
        await reloadLatestClientImportLog();

        return true;
      }}
    >
      {
        formik => (
          <Flex fullWidth>
            <Typography>Es wird eine neue DATEV-Abfrage erstellt. Über das Ergebnis wird laufend informiert.</Typography>
            {
              !hideLimits && (
                <Flex fullWidth gap="3">
                  <Flex row fullWidth justify="between">
                    <Flex gap="0">
                      <Typography bold>Abfrage eingrenzen</Typography>
                      <Typography size="12">Sie können die Abfrage auf bestimmte Entitätstypen begrenzen, um beispielsweise nur Ihre Mitarbeiter zu aktualisieren.</Typography>
                    </Flex>
                  </Flex>
                  <Flex row fullWidth wrap>
                    {
                      Object.values(DatevEndpoint).map(v => {

                        const isSelected = !!formik.values.limitTo?.includes(v);
                        const toggleSelected = () => {
                          if (isSelected) formik.setFieldValue("limitTo", formik.values.limitTo?.filter(l => l !== v));
                          else formik.setFieldValue("limitTo", [...(formik.values.limitTo ?? []), v]);
                        }

                        return (
                          <DatevEndpointPill
                            key={v}
                            endpoint={v}
                            active={isSelected}
                            onClick={toggleSelected}
                          />
                        )

                      })
                    }
                  </Flex>
                  <Pill
                    icon={
                      !formik.values.limitTo?.length
                        ? "check"
                        : "dash"
                    }
                    color={
                      !formik.values.limitTo?.length
                        ? "success"
                        : "error"
                    }
                    text={
                      !formik.values.limitTo?.length
                        ? "Vollständige Abfrage wird ausgeführt"
                        : "Nur ausgewählte Entitäten werden abgefragt"
                    }
                  />
                </Flex>

              )
            }
          </Flex>
        )
      }
    </ModalForm>
  )
}