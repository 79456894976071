import React from "react";
import { useGlobalMailTemplates } from "../../state/api/templates/useGlobalMailTemplates";
import { useMailTemplates } from "../../state/api/templates/useMailTemplates";
import { IGlobalMailTemplate, MailTemplate } from "../../types/globalMailTemplates.schema";
import { getId } from "../../util/mongoUtil";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import MailTemplateListItem from "./MailTemplatesListItem";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import UpdateMailTemplateButton from "./UpdateMailTemplateButton";
import UsedMailTemplateIndicator from "./UsedMailTemplateIndicator";

export interface ITenantMailTemplatesForTemplateType {
  type: MailTemplate,
  isInitiallyExpanded?: boolean
}

export default function TenantMailTemplatesForTemplateType(props: ITenantMailTemplatesForTemplateType) {

  const {
    type,
    isInitiallyExpanded
  } = props;

  const {
    loadingMailTemplates,
    mailTemplates
  } = useMailTemplates();

  const {
    globalMailTemplates
  } = useGlobalMailTemplates();

  const baseTemplate = globalMailTemplates?.find(g => g.templateType === type) ?? { templateType: type } as IGlobalMailTemplate;

  const templatesForType = mailTemplates?.filter(t => {
    if (t.templateType === type) return true;
    const bt = getId(t.baseTemplate);
    if (!bt) return false;
    return bt === getId(baseTemplate);
  }) ?? [];

  const [isExpanded, setIsExpanded] = React.useState<boolean>((isInitiallyExpanded && !!templatesForType.length) ?? false);

  const totalTemplates= (templatesForType.length ?? 0) + (baseTemplate ? 1 : 0);

  return (
    <Flex fullWidth>
      <Flex justify="between" fullWidth row>
        <Flex row>
          <MailTemplateTypeBanner template={type} />
          <UsedMailTemplateIndicator showAsUsingCustomMailTemplateIndicator template="" templateType={type} />
        </Flex>
        <Flex row>
          <Button
            disabled={!totalTemplates}
            loading={loadingMailTemplates}
            onClick={() => setIsExpanded(!isExpanded)}
            icon={isExpanded ? "chevron-up" : "chevron-down"}
            text={(isExpanded && !!totalTemplates) ? "Ausblenden" : `${totalTemplates} anzeigen`}
          />
          <UpdateMailTemplateButton isCreate templateType={type} baseTemplate={baseTemplate} />
        </Flex>
      </Flex>
      {
        isExpanded && (
          <Flex fullWidth row wrap>
            {
              baseTemplate && <MailTemplateListItem key={baseTemplate._id} template={baseTemplate} isBaseTemplate />
            }
            {
              templatesForType.map(t => <MailTemplateListItem key={t._id} template={t} />)
            }
          </Flex>
        )
      }
    </Flex>
  )
}