import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import Button from "../../../buttons/Button";
import CopyToClipboardButton from "../../../buttons/CopyToClipboardButton";
import Flex from "../../../container/Flex";
import ModalDialog from "../../../modal/ModalDialog";
import { useUser } from "../../../../state/api/user/useUser";

export default function MailItemDebugInfoModal({ message }: { message: Message }) {

  const {
    user
  } = useUser();

  if (!user?.isSuperAdmin) return null;

  const link = `https://graph.microsoft.com/v1.0/me/messages/${message.id}`;

  return (
    <ModalDialog
      title="Erweiterte Informationen"
      button={<Button text="Erweiterte Informationen" icon="bug" variant="icon" className="align-self-end" />}
    >
      {
        close => (
          <Flex className="w-100" gap={4}>
            <Flex gap={1} className="w-100">
              <Flex row className="w-100" justify="between">
                <strong>Nachrichten-URL</strong>
                {
                  link && <CopyToClipboardButton value={link} />
                }
              </Flex>
              <span className="text-break text-wrap">{link}</span>
            </Flex>
            <Flex gap={1} className="w-100">
              <Flex row className="w-100" justify="between">
                <strong>Internet-Message-ID</strong>
                {
                  message.internetMessageId && <CopyToClipboardButton value={message.internetMessageId} />
                }
              </Flex>
              <span className="text-break text-wrap">{message.internetMessageId || "-"}</span>
            </Flex>
            <Flex gap={1} className="w-100">
              <Flex row className="w-100" justify="between">
                <strong>ID</strong>
                {
                  message.id && <CopyToClipboardButton value={message.id} />
                }
              </Flex>
              <span className="text-break text-wrap">{message.id || "-"}</span>
            </Flex>
            <Flex gap={1} className="w-100">
              <Flex row className="w-100" justify="between">
                <strong>Ordner</strong>
                {
                  message.parentFolderId && <CopyToClipboardButton value={message.parentFolderId} />
                }
              </Flex>
              <span className="text-break text-wrap">{message.parentFolderId || "-"}</span>
            </Flex>
            <Flex gap={1} className="w-100">
              <Flex row className="w-100" justify="between">
                <strong>Konversation</strong>
                {
                  message.conversationId && <CopyToClipboardButton value={message.conversationId} />
                }
              </Flex>
              <span className="text-break text-wrap">{message.conversationId || "-"}</span>
            </Flex>
            <Flex gap={1} className="w-100">
              <Flex row className="w-100" justify="between">
                <strong>Konversations-Index</strong>
                {
                  message.conversationIndex && <CopyToClipboardButton value={message.conversationIndex} />
                }
              </Flex>
              <span className="text-break text-wrap">{message.conversationIndex || "-"}</span>
            </Flex>
            <Button className="align-self-end" text="Schließen" onClick={async () => close()} />
          </Flex>
        )
      }
    </ModalDialog>
  )
}