import React from "react";
import { AppColor } from "../../app/AppStyles";
import Button from "../buttons/Button";
import Expandable from "../comboBox/Expandable";
import Typography, { TypographySize } from "../text/Typography";
import "./Select.css";
import Icon from "../icons/Icon";
import Flex from "../container/Flex";

export type SelectItem<T extends (string | number)> = T | {
  label: string,
  data: T
}

export interface ISelectProps<T extends (string | number)> {
  values: Array<SelectItem<T>>,
  value?: T | Array<T>,
  enableReinitialize?: boolean,
  getLabel?: (data: T) => string,
  onChange: (selectedValue: T, allValues?: Array<T>) => void,
  closeOnSelect?: boolean,
  icon?: string,
  text?: string,
  allowMultiple?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  bold?: boolean,
  color?: AppColor,
  size?: TypographySize,
  label?: string
}

export default function Select<T extends (string | number)>(props: ISelectProps<T>) {

  const {
    closeOnSelect,
    color,
    icon,
    enableReinitialize,
    text,
    values,
    bold,
    disabled,
    label,
    placeholder,
    onChange,
    allowMultiple = false,
    getLabel,
    size,
    value,
    readOnly
  } = props;

  const [selectedItems, setSelectedItems] = React.useState<Map<T, SelectItem<T>>>(new Map());

  const getSelectLabel = () => {
    if (text) return text;
    if (!selectedItems?.size) return placeholder ?? "Auswählen...";

    const selected = Array.from(selectedItems.values());
    const labels = [];

    for (const s of selected) labels.push(getLabelFromValue(s));

    return labels.join(", ");
  }

  const getLabelFromValue = (value: SelectItem<T>) => {
    switch (typeof value) {
      case "string":
      case "number":

        return getLabel?.(value) ?? `${value}`;

      default:

        return value.label;
    }
  }

  const getDataFromValue = (value: SelectItem<T>) => {
    switch (typeof value) {
      case "string":
      case "number":

        return value;

      default:

        return value.data;
    }
  }

  const loadInitialValues = () => {
    if (value === null || value === undefined) return setSelectedItems(new Map());
    if (!values?.length) return setSelectedItems(new Map());

    const selection = new Map<T, SelectItem<T>>();
    const selectedKeys = Array.isArray(value) ? new Set(value) : new Set([value]);

    for (const v of values) {
      const key = getDataFromValue(v);
      if (selectedKeys.has(key)) selection.set(key, v);
    }

    setSelectedItems(selection);
  }

  React.useEffect(() => loadInitialValues(), []);
  React.useEffect(() => {
    if (!enableReinitialize) return;
    loadInitialValues();
  }, [value, values]);

  const clickHandler = (o: SelectItem<T>) => {
    const key = getDataFromValue(o);
    const n = new Map(selectedItems);

    if (allowMultiple) {
      if (n.has(key)) n.delete(key);
      else n.set(key, o);
    }
    else {
      n.clear();
      n.set(key, o);
    }

    setSelectedItems(n);
    onChange(key, Array.from(n.keys()));
  }

  return (
    <div className="d-flex flex-column position-relative gap-1 select-button" >
      {
        label && <Typography bold={bold} color="primary">{label}</Typography>
      }
      <Expandable
        disabled={disabled || readOnly}
        expander={(
          <Button
            readOnly={readOnly}
            disabled={disabled}
            icon={icon}
            className="select-button-button"
            color={color ?? "primary"}
            variant={disabled ? "text" : "subtle"}
            text={getSelectLabel()}
          />
        )}
      >
        {
          close => (
            <div className="d-flex flex-column pb-2 pt-2 gap-0 w-100 h-100 text-start">
              {
                values && values.map((o: SelectItem<T>) => {

                  const k = getDataFromValue(o);
                  const isSelected = selectedItems.has(k);

                  return (
                    <div
                      key={`select-item-${k}`}
                      className="w-100 p-1 ps-3 pe-3 select-button-item"
                      onClick={() => {
                        clickHandler(o);
                        if (closeOnSelect) close();
                      }}
                    >
                      <Flex row gap="1">
                        <Typography
                          size={size}
                          bold
                          align="start"
                          userSelect="none"
                          color={isSelected ? "primary" : "muted"}
                          upper
                        >
                          {getLabelFromValue(o)}
                        </Typography>
                        {
                          isSelected && <Icon icon="check" color="primary" size={14} />
                        }
                      </Flex>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </Expandable>
    </div>
  )
}

