import React from "react";
import { StringMappingType } from "typescript";
import { MirrorTicketStatusToMailBehavior } from "../../../types/ApiTypes";
import Typography from "../../text/Typography";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";

export interface ITicketMailBehaviorSelectProps {
  label: string,
  onChange: (value: MirrorTicketStatusToMailBehavior | MirrorTicketStatusToMailBehavior[]) => void,
  allowMultiple?: boolean,
  value: MirrorTicketStatusToMailBehavior | MirrorTicketStatusToMailBehavior[]
}

export default function TicketMailBehaviorSelect(props: ITicketMailBehaviorSelectProps) {

  const {
    label,
    onChange,
    allowMultiple,
    value
  } = props;

  const getLabel = (v: MirrorTicketStatusToMailBehavior) => {
    switch (v) {
      case MirrorTicketStatusToMailBehavior.AfterClose: return "Nach Schließen";
      case MirrorTicketStatusToMailBehavior.AfterDelete: return "Nach Löschen";
      case MirrorTicketStatusToMailBehavior.Immediately: return "Bei Mailerhalt";
      case MirrorTicketStatusToMailBehavior.OnFirstMessage: return "Bei erster Nachricht eines Mitarbeiters";
      case MirrorTicketStatusToMailBehavior.Never: return "Nie";
    }
  }

  return (
    <Select
      label={label}
      getLabel={getLabel}
      value={value}
      enableReinitialize
      allowMultiple={allowMultiple}
      onChange={(value, allValues) => {

        if (!allowMultiple) {
          onChange(value);
          return;
        }

        switch (value) {
          case MirrorTicketStatusToMailBehavior.Never:

            onChange([MirrorTicketStatusToMailBehavior.Never]);
            break;

          default:

            const valuesWithoutNever = allValues?.filter(v => v !== MirrorTicketStatusToMailBehavior.Never) ?? [];
            onChange(valuesWithoutNever);
            break;
        }
      }}
      values={Object.values(MirrorTicketStatusToMailBehavior)}
    />
  )
}