import { IClientImportLog } from "../types/clientImportLog.schema"

export default function useClientImportUtil() {

  const isImportRunning = (run?: IClientImportLog) => {
    if (!run) return false;
    return !run.failed && !run.completed && !run.aborted && !run.completedAt;
  }

  const isImportDone = (run?: IClientImportLog) => {
    if (!run) return false;
    return !!run.completed || !!run.completedAt;
  }

  const getImportIcon = (run?: IClientImportLog) => {

    if (!run) return "";
    if (isImportDone(run)) return "check";
    else if (run.failed) return "bug";
    else if (run.aborted) return "slash";
    return "";
  }

  return {
    isImportRunning,
    isImportDone,
    getImportIcon
  }
}