import React from "react";
import { DatevEndpoint } from "../../types/datev.import.types";
import Pill from "../pills/Pill";

export interface IDatevEndpointPillProps {
  active?: boolean,
  readOnly?: boolean,
  onClick?: () => void,
  endpoint: DatevEndpoint
}

export default function DatevEndpointPill(props: IDatevEndpointPillProps) {

  const {
    active,
    readOnly,
    endpoint,
    onClick
  } = props;

  const getLabelForEndpoint = (endpoint: DatevEndpoint) => {
    switch (endpoint) {
      case DatevEndpoint.Addressees: return "Adressaten";
      case DatevEndpoint.AreasOfResponsibility: return "Verantwortungsbereiche";
      case DatevEndpoint.Clients: return "Mandanten";
      case DatevEndpoint.CountryCodes: return "Ländercodes";
      case DatevEndpoint.EmployeeResponsibilities: return "Mitarbeiterverantwortung";
      case DatevEndpoint.Employees: return "Mitarbeiter";
      case DatevEndpoint.LegalForms: return "Rechtsformen";
      case DatevEndpoint.Relationships: return "Beziehungen";
      case DatevEndpoint.RelationshipTypes: return "Beziehungstypen";
    }
  }

  return (
    <Pill
      text={getLabelForEndpoint(endpoint)}
      onClick={onClick}
      color={active ? "#ACF" : "#F3F3F3"}
      icon={readOnly ? "" : (active ? "check" : "x")}
    />
  )

}