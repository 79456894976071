import React from "react";
import Card from "../components/card/Card";
import ConnectorSettings from "../components/connectors/ConnectorSettings";
import GlobalSettings from "../components/settings/GlobalSettings";
import TabSwitcher from "../components/tabswitcher/TabSwitcher";
import TenantUpdateForm from "../components/tenant/settings/TenantUpdateForm";
import UserSettings from "../components/user/UserSettings";
import useTenantSettings from "../hooks/useTenantSettings";
import Page from "../components/container/Page";
import { Locale } from "../locale/Locale";
import { useTenant } from "../state/api/tenant/useTenant";
import { useUser } from "../state/api/user/useUser";
import useLabels, { Label } from "../hooks/useLabels";

enum SettingsTab {
  User = "user",
  Portal = "portal",
  Interfaces = "interfaces",
  Tenant = "tenant"
}
export default function Settings() {

  const [tab, setTab] = React.useState<SettingsTab>(SettingsTab.User);

  const { titles } = useTenantSettings(true);
  const { tenant } = useTenant();
  const { user } = useUser();

  const getLabel = useLabels();

  if (!user) return null;

  const getContent = () => {
    switch (tab) {
      case SettingsTab.Interfaces: return <ConnectorSettings />;
      case SettingsTab.Portal: return <GlobalSettings />;
      case SettingsTab.Tenant: return tenant ? <TenantUpdateForm tenant={tenant} canDelete={false} /> : null;
      case SettingsTab.User: return <UserSettings />
    }
  }

  return (
    <Page header={Locale.titles.settings}>
      <TabSwitcher
        tabQueryParamKey="details"
        saveActiveTab={(t) => setTab(t as SettingsTab)}
        tabs={[
          {
            data: SettingsTab.User,
            icon: "person-badge-fill",
            label: "Ihr Konto"
          },
          {
            data: SettingsTab.Tenant,
            icon: "building",
            label: `${getLabel(Label.TenantName)}`,
            permissions: ["tenant.own.update"]
          },
          {
            data: SettingsTab.Portal,
            icon: "gear-wide-connected",
            label: "Systemeinstellungen",
            hidden: !user?.isSuperAdmin
          },
          {
            icon: "router-fill",
            data: SettingsTab.Interfaces,
            label: "Schnittstellen",
            permissions: [
              "interfaces.cloudGateway.read",
              "interfaces.datev.read",
              "interfaces.pointchamp.read"
            ]
          },
        ]}
      />
      <Card style={{overflowY: "auto"}}>
        {getContent()}
      </Card>
    </Page>
  )
}