import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../../hooks/useTiggiQuery";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export const useTicketMessage = (id: string, opts?: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<ITicketMessage>(ApiRoutes.TicketMessages.ById.getValue({ param: RouteParam.TicketMessageId, value: id }), opts);

  return {
    message: data,
    loadingMessage: isLoading,
    reloadMessage: mutate
  }
}