import { ITemplateElement, MailTemplate, TemplateElementType } from "../types/globalMailTemplates.schema";
import { TemplatePlaceholder } from "../types/templatePlaceholder.schema";
import useTenantSettings from "./useTenantSettings";

export const useTemplateUtil = () => {

    const {
        titles
    } = useTenantSettings();

    const getStringified = (values: Array<ITemplateElement>, asHtml?: boolean) => {
        if (!values || !values.length) return "";

        const getLineBreak = () => asHtml ? "<br/><br/>" : "\n\n";

        return values.map(v => {
            switch (v.type) {
                case TemplateElementType.Salutation: return `--DYNAMISCHE ANREDE--${getLineBreak()}`;
                case TemplateElementType.LineBreak: return getLineBreak();
                
                case TemplateElementType.Placeholder: 
                    
                    if (!v.placeholder) return v.content;
                    return v.content.replace(v.placeholder, `{{${getPlaceholderDisplayName(v.placeholder)}}}`);
                
                case TemplateElementType.Text: 
                    return v.content;
                
                case TemplateElementType.TicketAnswerPrompt:

                    return "--TICKETANTWORT TRENNZEILE--";
            }
        }).join(" ");
    }

    
    const getPlaceholderDisplayName = (p: TemplatePlaceholder) => {
        switch (p) {
            case TemplatePlaceholder.ClientName: return "Firma des Mandanten";
            case TemplatePlaceholder.TicketId: return "Ticket-ID";
            case TemplatePlaceholder.TicketMessage: return "Neueste Ticketnachricht";
            case TemplatePlaceholder.TicketSubject: return "Ticket-Betreff";
            case TemplatePlaceholder.UserFirstName: return "Vorname des Empfängers";
            case TemplatePlaceholder.TicketResubmissionAt: return "Wiedervorlage-Datum Ticket";
            case TemplatePlaceholder.UserLastName: return "Nachname des Empfängers";
            case TemplatePlaceholder.TenantName: return `Name ${titles?.tenantWord || "Tenant"}`;
            case TemplatePlaceholder.DocumentClearanceLink: return `Link für ${titles?.documentClearanceWord || "Belegfreigabe"}`;
            case TemplatePlaceholder.SenderFirstName: return "Vorname des Absenders";
            case TemplatePlaceholder.SenderLastName: return "Nachname des Absenders";
            case TemplatePlaceholder.TaskSubject: return "Aufgabenbetreff";
            case TemplatePlaceholder.TicketLink: return "Link zum Ticket";
            case TemplatePlaceholder.UserAdditionalPrefix: return "Zusätzliche Anrede des Empfängers";
            case TemplatePlaceholder.UserTitle: return "Titel des Empfängers";
        }
    }


    const getDisplayNameForMailTemplate = (type: MailTemplate): string => {
        switch (type) {
            case MailTemplate.TaskDue: return "Aufgabe fällig";
            case MailTemplate.DocumentClearanceReminderTextOnly: return "Dokumentenfreigabe Erinnerung (Nur Text)";
            case MailTemplate.DocumentClearanceReminderWithLink: return "Dokumentenfreigabe Erinnerung (Mit Link)";
            case MailTemplate.TicketAssigned: return "Ticketzuweisung";
            case MailTemplate.TicketClosed: return "Ticket geschlossen";
            case MailTemplate.TicketCreated: return "Ticket erstellt";
            case MailTemplate.TicketDeleted: return "Ticket gelöscht";
            case MailTemplate.TicketEscalated: return "Ticket eskaliert";
            case MailTemplate.TicketEscalationMailToClient: return "Ticket-Eskalation Benachrichtigung an Kunde";
            case MailTemplate.TicketEscalationMailToSupervisor: return "Ticket-Eskalation Benachrichtigung an Vorgesetzten";
            case MailTemplate.TicketReopened: return "Ticket wiedereröffnet";
            case MailTemplate.TicketMessage: return "Ticket-Nachricht";
            case MailTemplate.TicketPdf: return "Ticket-PDF";
            case MailTemplate.TicketSolved: return "Ticket gelöst";
            case MailTemplate.TicketSetToResubmission: return "Ticket auf Wiedervorlage";
            case MailTemplate.TicketUpdated: return "Ticket aktualisiert";
            case MailTemplate.NewEmployeeInvitation: return "Mitarbeiter-Einladung (Onboarding)";
            case MailTemplate.TicketMention: return "Erwähnung in Ticketnachricht";
        }
    }

    const getDisplayNameForTemplateType = (t: TemplateElementType): string => {
        switch (t) {
            case TemplateElementType.Text: return "Text";
            case TemplateElementType.Placeholder: return "Platzhalter";
            case TemplateElementType.Link: return "Link";
            case TemplateElementType.Image: return "Bild";
            case TemplateElementType.LineBreak: return "Zeilenumbruch";
            case TemplateElementType.TicketAnswerPrompt: return "Ticket-Antwortzeile";
            case TemplateElementType.Salutation: return "Anrede";
            default: return "Unbekannter Typ";
        }
    }

    return {
        getStringified,
        getDisplayNameForMailTemplate,
        getDisplayNameForTemplateType,
        getPlaceholderDisplayName
    }
}