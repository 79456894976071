import React from "react";
import PermissionContainer from "./PermissionContainer";
import Typography from "../text/Typography";
import Icon from "../icons/Icon";
import Flex from "../container/Flex";
import Card from "../card/Card";
import PermissionCheckboxes, { IPermissionCheckboxProps } from "./PermissionCheckboxes";
import { useFormikContext } from "formik";
import { IPermission, IRole } from "../../types/ApiTypes";

export interface IPermissionGroupProps {
  label: string,
  disabled?: boolean,
  readOnly?: boolean,
  data?: Array<IPermissionCheckboxProps>,
  children?: React.ReactElement[] | React.ReactElement
}

export default function PermissionGroup(props: IPermissionGroupProps) {

  const {
    label,
    readOnly,
    disabled,
    children,
    data
  } = props;

  const {
    values,
    setFieldValue
  } = useFormikContext<IRole>();

  const [allSelectedKeys, setAllSelectedKeys] = React.useState<{ [key in keyof IPermission]: boolean }>({ create: false, delete: false, read: false, update: false });
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const handleSelectAll = (key: keyof IPermission) => {
    if (readOnly) return;
    if (!data || !data.length) return;

    const hasSelection = allSelectedKeys[key];

    for (const d of data) {
      setFieldValue(`permissions.${d.name}.${key}`, !hasSelection);
    }

    setAllSelectedKeys({ ...allSelectedKeys, [key]: !hasSelection });
  }

  if (disabled) return (
    <Typography color="error">Die Berechtigung "{label}" ist für diese Rolle nicht verfügbar.</Typography>
  )

  const iconSize = isExpanded ? 18 : 0;

  if (!children && !data) return null;

  return (
    <Card
      expanded={isExpanded}
      header={
        <PermissionContainer
          isHeader
          title={
            <Flex row onClick={() => setIsExpanded(!isExpanded)}>
              <Icon color="primary" icon={isExpanded ? "chevron-up" : "chevron-down"} />
              <Typography color="primary" bold>{label}</Typography>
            </Flex>
          }
          create={<Icon onClick={() => handleSelectAll("create")} icon="plus-circle" size={iconSize} color="primary" tooltip="Berechtigt zum Erstellen von Ressourcen" />}
          read={<Icon onClick={() => handleSelectAll("read")} icon="eye" size={iconSize} color="primary" tooltip="Berechtigt zum Lesen von Ressourcen" />}
          update={<Icon onClick={() => handleSelectAll("update")} icon="pen" size={iconSize} color="primary" tooltip="Berechtigt zum Bearbeiten von Ressourcen" />}
          del={<Icon onClick={() => handleSelectAll("delete")} icon="trash-fill" size={iconSize} color="error" tooltip="Berechtigt zum Löschen von Ressourcen" />}
        />
      }
    >
      {
        isExpanded
          ? (
            <Flex fullWidth gap={3}>
              {
                children
                  ? React.Children.map(children, c => React.cloneElement(c, { readOnly }))
                  : (
                    data && data.length
                      ? data.map((d, index) => <PermissionCheckboxes key={index} {...d} readOnly={readOnly} />)
                      : null
                  )
              }
            </Flex>
          )
          : null
      }
    </Card>
  )
}