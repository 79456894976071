import React from "react";
import ClientList from "../../components/clients/ClientList";
import DeleteClientsButton from "../../components/clients/DeleteClientsButton";
import DatevImportDialog from "../../components/datev/DatevImportDialog";
import WithPermissions from "../../components/permissions/WithPermissions";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import ClientImport from "../../components/datev/import/ClientImport";
import ClientImportProgress from "../../components/datev/import/ClientImportProgress";
import NewCloudGatewayImportButton from "../../components/datev/NewCloudGatewayImportButton";

export enum ClientManagementTab {
  Clients = "clients",
  Import = "import"
}

export default function ClientManagement() {

  const [tab, setTab] = React.useState<ClientManagementTab>(ClientManagementTab.Clients);

  return (
    <WithPermissions permissions={["clients.all.read"]}>
      <Page
        header={Locale.titles.clientManagement}
        actionButtons={[
          tab === ClientManagementTab.Clients
            ? <DeleteClientsButton key="delete-clients-button" />
            : <NewCloudGatewayImportButton key="new-cloud-gateway-import-btn" />
        ]}
      >
        <WithPermissions permissions={["interfaces.datev.update", "interfaces.cloudGateway.update"]} >
          <TabSwitcher
            tabQueryParamKey="show"
            tabs={[
              { data: ClientManagementTab.Clients, label: "Alle Mandate" },
              { data: ClientManagementTab.Import, label: "Import & Schnittstellen" }
            ]}
            saveActiveTab={t => setTab(t as ClientManagementTab)}
          />
        </WithPermissions>
        <ClientImportProgress />
        {
          tab === ClientManagementTab.Clients
            ? <ClientList />
            : <ClientImport />
        }
      </Page>
    </WithPermissions>
  )
}