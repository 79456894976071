import React from "react";
import useClientUtil from "../../hooks/useClientUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useAnyUserAlias } from "../../state/api/user/useAnyUserAlias";
import { useAnyUserClients } from "../../state/api/user/useAnyUserClients";
import AddresseePill from "../addressee/AddresseeBanner";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import Typography from "../text/Typography";
import UserUpdateForm from "../user/UserUpdateForm";
import { ISortedClientContact } from "./ClientContactList";

export default function ClientContactListItem({ clientContact }: { clientContact: ISortedClientContact }) {

  const { loadingUserClients, reloadUserClients, userClients } = useAnyUserClients(clientContact.user ? clientContact.user._id : "");
  const { userAlias, loadingUserAlias } = useAnyUserAlias(clientContact.user ? clientContact.user._id : "");

  const {
    getName
  } = useUserUtil();

  return (
    <TableRow>
      <TableCell>
        {
          clientContact.user
            ? (
              <div className="d-flex flex-column">
                <strong>{getName(clientContact.user)}</strong>
                <span>{clientContact.user?.mailAddress}</span>
              </div>
            )
            : (
              <div className="d-flex flex-column">
                <strong>Kein Kontakt zugewiesen</strong>
                <span>Eventuell fehlt die Mailaddresse</span>
              </div>
            )
        }
      </TableCell>
      <TableCell>
        <div className="d-flex flex-row flex-wrap gap-1">
          {
            !!clientContact?.addressees?.length
              ? clientContact.addressees.map(a => <AddresseePill addressee={a} showAddress showName key={a._id} />)
              : <span>-</span>
          }
        </div>
      </TableCell>
      <TableCell>
        <div className="d-flex flex-row flex-wrap gap-1">
          {
            loadingUserClients
              ? <LoadingSpinner />
              : (
                userClients?.clients?.length
                  ? userClients.clients.map(c => <ClientTypeBanner key={c._id} client={c} showClientName />)
                  : <span>-</span>
              )
          }
        </div>
      </TableCell>
      <TableCell>
        <Flex gap={1}>
          {
            loadingUserAlias
              ? <LoadingSpinner />
              : (
                userAlias?.length
                  ? userAlias.map(a => <Typography key={a.aliasMailAddress}>{a.aliasMailAddress}</Typography>)
                  : <span>-</span>
              )
          }
        </Flex>
      </TableCell>
      <TableCell>
        {
          clientContact.user && <UserUpdateForm user={clientContact.user} isClientContact={true} />
        }
      </TableCell>
    </TableRow>
  )
}