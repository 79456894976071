import React from "react";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddresseeRelationships } from "../../state/api/addressees/useAddresseeRelationships";
import { useAddresseeRelationshipTypes } from "../../state/api/addressees/useAddresseeRelationshipTypes";
import { useAddressees } from "../../state/api/addressees/useAddressees";
import { useLegalForms } from "../../state/api/legalForms/useLegalForms";
import { useAnyUserClients } from "../../state/api/user/useAnyUserClients";
import { IWithAddressee } from "../../types/addressee.schema";
import { DatevAddresseeType } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import { getId } from "../../util/mongoUtil";
import CopyToClipboardButton from "../buttons/CopyToClipboardButton";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import AddresseeRelationshipItem from "./AddresseeRelationshipItem";
import AddresseeAddresses from "./detail/AddresseeAddresses";
import AddresseeBankAccounts from "./detail/AddresseeBankAccounts";
import AddresseeCommunications from "./detail/AddresseeCommunications";
import AddresseeTaxOffice from "./detail/AddresseeTaxOffices";

export default function AddresseeDetail(props: IWithAddressee) {

  const {
    addressee
  } = props;

  const {
    getName,
    getMail
  } = useUserUtil();

  const {
    addressees
  } = useAddressees();

  const { loadingAddresseeRelationshipTypes } = useAddresseeRelationshipTypes();

  const { loadingAddresseeRelationships, addresseeRelationships } = useAddresseeRelationships(addressee?._id ?? "");
  const { legalFormsById } = useLegalForms();
  const { loadingUserClients, userClients } = useAnyUserClients(getId(addressee?.user));

  const addresseeUserExists = (user?: string): number => {
    if (!user) return 0;
    const exists = addressees?.filter(a => a.user && getId(a.user) === getId(user));
    return exists?.length ? exists.length - 1 : 0;
  }

  const isAddresseeUserAssignedToOthers = addresseeUserExists(addressee?.user);

  if (!addressee) return null;

  return (
    <Flex fullWidth gap="5" className="pb-5 overflow-auto">
      <Flex fullWidth gap="3">
        <Typography bold size="18" color="primary">Metadaten</Typography>
        <Flex fullWidth>
          <Flex gap="0">
            <Typography bold color="primary">
              {addressee.type === DatevAddresseeType.LegalPerson ? "Gründungsdatum" : "Geburtsdatum"}
            </Typography>
            <Typography>{formatDate(addressee.date_of_foundation ?? addressee.date_of_birth)}</Typography>
          </Flex>
          {
            addressee.type === DatevAddresseeType.LegalPerson && (
              <Flex gap="0">
                <Typography bold color="primary">Rechtsform</Typography>
                <Typography>{legalFormsById?.[addressee.legalForm]?.long_name ?? "Rechtl. Einheit"}</Typography>
              </Flex>
            )
          }
          {
            addressee.detail?.current_enterprise_purpose && (
              <Flex gap="0">
                <Typography bold color="primary">Unternehmenszweck</Typography>
                <Typography>{addressee.detail.current_enterprise_purpose}</Typography>
              </Flex>
            )
          }
          {
            addressee.detail?.current_registration_number && (
              <Flex gap="0">
                <Typography bold color="primary">Registereintrag</Typography>  
                <Flex gap="3" row>
                  <Typography>{addressee.detail.current_kind_of_register_court}{addressee.detail.current_registration_number}</Typography>
                  <CopyToClipboardButton color="primary" variant="text" value={`${addressee.detail.current_kind_of_register_court}${addressee.detail.current_registration_number}`} />
                </Flex>
              </Flex>
            )
          }
        </Flex>
      </Flex>
      <Flex fullWidth gap="3">
        <Typography bold size="18" color="primary">Beziehungen</Typography>
        {
          addressee.user && (
            <Flex fullWidth>
              <Typography bold color="primary">Nutzer</Typography>
              <Pill className="gap-3 justify-content-start" color={isAddresseeUserAssignedToOthers ? "#FFF0F0" : "#F0F0F0"}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <Icon icon="person-fill" style={{ marginBottom: "-5px" }} size={14} />
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <Icon icon="link" color={isAddresseeUserAssignedToOthers ? "error" : "success"} size={18} />
                    {
                      !!isAddresseeUserAssignedToOthers && <em style={{ fontSize: "0.8em" }}>+{isAddresseeUserAssignedToOthers} weitere</em>
                    }
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <strong>{getName(addressee.user)}</strong>
                  <span style={{ fontSize: "0.9em" }}>{getMail(addressee.user)}</span>
                </div>
              </Pill>
            </Flex>
          )
        }
        <Flex fullWidth >
          <Typography bold color="primary">Mandanten</Typography>
          <Flex fullWidth row wrap>
            {
              loadingUserClients
                ? <LoadingSpinner />
                : (
                  userClients?.clients?.length
                    ? userClients.clients.map(c => <ClientTypeBanner key={c._id} isLink client={c} showClientName />)
                    : <span>Keine Mandatsbeziehungen</span>
                )
            }
          </Flex>
        </Flex>
        {
          !!addresseeRelationships?.length && (
            <Flex fullWidth>
              <Flex row>
                {(loadingAddresseeRelationshipTypes || loadingAddresseeRelationships) && <LoadingSpinner size={12} />}
                <Typography bold color="primary">Sonstige</Typography>
              </Flex>
              <Flex row wrap fullWidth>
                {
                  addresseeRelationships.map(r => <AddresseeRelationshipItem key={r._id} addressee={addressee} relationship={r} isLink />)
                }
              </Flex>
            </Flex>
          )
        }
      </Flex>
      <Flex fullWidth>
        <Typography bold size="18" color="primary">Kontakt</Typography>
        <Flex fullWidth gap="4">
          <AddresseeCommunications addressee={addressee} />
          <AddresseeAddresses addressee={addressee} />
        </Flex>
      </Flex>
      <Flex fullWidth gap="4">
        <Typography bold size="18" color="primary">Steuern & Finanzen</Typography>
        <Flex fullWidth gap="3">
          {
            (addressee.eu_vat_id_country_code && addressee.eu_vat_id_number) && (
              <Flex gap="0">
                <Typography bold color="primary">Umsatzsteuer-ID</Typography>
                <Flex row>
                  <Typography>{`${addressee.eu_vat_id_country_code}${addressee.eu_vat_id_number}`}</Typography>
                  <CopyToClipboardButton color="primary" variant="text" value={`${addressee.eu_vat_id_country_code}${addressee.eu_vat_id_number}`} />
                </Flex>
              </Flex>
            )
          }
          {
            addressee.tax_identification_number && (
              <Flex gap="0">
                <Typography bold color="primary">Steuer-ID</Typography>
                <Flex row>
                  <Typography>{addressee.tax_identification_number}</Typography>
                  <CopyToClipboardButton color="primary" variant="text" value={addressee.tax_identification_number} />
                </Flex>
              </Flex>
            )
          }
          <AddresseeTaxOffice addressee={addressee} />
        </Flex>
        <AddresseeBankAccounts addressee={addressee} />
      </Flex>
    </Flex>
  )
}