export enum ServerActionStatus {
  Processing = "processing",
  Completed = "completed",
  Failed = "failed"
}

export enum ServerAction {
  SendMail = "sendMail"
}

export interface IWithServerAction {
  status: ServerActionStatus;
  action: ServerAction;
}