import { IAttachment, IMongooseDocument } from "./ApiTypes"
import { IWithServerAction } from "./serverAction.schema"

export interface ITicketMessageText {
  text: string,
  createdAt: Date,
  readBy: Array<string>
}

export enum TicketMessageSource {
  HTTP = "http",
  Mail = "mail",
}

export interface ITicketMessageCcRecipient {
  user?: string,
  mailAddress?: string
}

export interface ITicketMessageDocument extends IMongooseDocument {
  sentBy: string,
  senderMailAddress: string,
  senderName: string,
  attachments: Array<IAttachment>,
  isRead: boolean,
  isReadAt: Date,
  isEdited: boolean,
  isReadBy: string,
  content: Array<ITicketMessageText>,
  mentions: Array<string>,
  isSystemMessage: boolean,
  ticketActivity: string,
  isSolution: boolean,
  ticket: string,
  cc: Array<ITicketMessageCcRecipient>,
  source: TicketMessageSource,
  sourceLink?: string
}

export interface ITicketMessage extends ITicketMessageDocument, IWithServerAction {
}

