import React from "react";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import { IMailTemplate } from "../../types/ApiTypes";
import Card from "../card/Card";
import Flex from "../container/Flex";
import Pill from "../pills/Pill";
import ResponsiveText from "../text/ResponsiveText";
import Typography from "../text/Typography";
import "./MailTemplatesListItem.css";
import TestMailTemplateButton from "./TestMailTemplateButton";
import UpdateMailTemplateButton from "./UpdateMailTemplateButton";
import UsedMailTemplateIndicator from "./UsedMailTemplateIndicator";

export interface IMailTemplateListItemProps {
  template: IMailTemplate,
  isBaseTemplate?: boolean
}

export default function MailTemplateListItem({ template, isBaseTemplate }: IMailTemplateListItemProps) {

  const { getStringified } = useTemplateUtil();

  return (
    <Card
      wrapperClass="mw-100 h-100"
      header={
        <Flex fullWidth justify="between">
          <Typography bold>{getStringified(template.subject)}</Typography>
          <Flex row>
            {
              isBaseTemplate && (
                <Pill
                  text="Basisvorlage"
                  color="#D34423"
                  icon="globe"
                />
              )
            }
            <UsedMailTemplateIndicator
              template={template._id}
              templateType={template.templateType ?? template.baseTemplate?.templateType}
            />
          </Flex>
        </Flex>
      }
    >
      <Flex fullHeight fullWidth justify="between">
        <ResponsiveText content={getStringified(template.body)} />
        {
          !isBaseTemplate && (
            <Flex row fullWidth justify="between">
              <TestMailTemplateButton templateId={template._id} />
              <UpdateMailTemplateButton existingTemplate={template} />
            </Flex>
          )
        }
      </Flex>
    </Card>
  )
}