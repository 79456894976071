import IElementProps from "../../types/element.types";
import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { AppColor } from "../../app/AppStyles";
import { getTextColorFromBackground } from "../../util/util";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import Typography from "../text/Typography";
import "./LoadingSpinner.css";

interface ILoadingSpinnerProps extends IElementProps {
  fullscreen?: boolean,
  centered?: boolean,
  size?: number,
  color?: AppColor,
  asTableRow?: boolean,
  hexColor?: string,
  invertThisColor?: string,
  text?: string
}

export default function LoadingSpinner({ fullscreen, asTableRow, invertThisColor, className, centered = false, size = 18, color, hexColor, text }: ILoadingSpinnerProps) {

  const actualClass = generateClassName(className, "d-flex flex-row loading-spinner-container align-items-center", {
    value: fullscreen,
    onTrue: "h-100 w-100"
  }, {
    base: "justify-content-",
    value: centered,
    onTrue: "center",
    standard: "start"
  }, {
    value: text,
    onTrue: "gap-2"
  });

  const style = generateStyle(
    { name: "width", value: size, unit: "px", important: true },
    { name: "height", value: size, unit: "px", important: true },
    { name: "borderWidth", value: 2, applyCondition: size < 10 },
    { name: "color", isVar: !invertThisColor && !hexColor, value: invertThisColor ? getTextColorFromBackground(invertThisColor) : (hexColor || color) }
  );

  const loadingSpinner = (
    <div className={actualClass}>
      <div style={style} className="spinner spinner-border loading-spinner"></div>
      {
        text && <Typography color={color}>{text}</Typography>
      }
    </div>
  )

  if (asTableRow) return <TableRow><TableCell>{loadingSpinner}</TableCell></TableRow>

  return loadingSpinner;
}