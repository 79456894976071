import React, { PropsWithChildren } from "react";
import Icon from "../icons/Icon";
import "./Dialog.css";
import Flex from "../container/Flex";
import { generateClassName } from "../../hooks/useAttributes";

export interface IDialogBase {
  fullscreen?: boolean,
}

interface IDialogProps extends IDialogBase {
  title: string,
  setVisible: (val: boolean) => void,
  onClose?: () => (Promise<any> | any),
  canClose?: boolean,
  sidebar?: React.ReactNode | ((closeForm: () => void) => React.ReactNode),
  children: React.ReactNode | ((closeForm: () => void) => React.ReactNode)
}

export default function Dialog(props: IDialogProps) {

  const {
    title,
    canClose,
    onClose,
    children,
    sidebar,
    fullscreen,
    setVisible
  } = props;

  const [sidebarHeight, setSidebarHeight] = React.useState<number>(0);
  const [height, setHeight] = React.useState<number>(0);
  const [resized, setResized] = React.useState<number>(0);
  const [zoom, setZoom] = React.useState<number>(1);

  const sizeTimer = React.useRef<any>();
  const windowSizeTimer = React.useRef<any>();
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClose = (checkCanClose: boolean = false) => {
    if (checkCanClose && !canClose) return;

    if (onClose) {
      try {
        onClose();
      }
      catch { }
    }

    setVisible(false);
  }

  const childrenContent = (
    typeof children === "function"
      ? children(handleClose)
      : children
  )

  const sidebarContent = (
    typeof sidebar === "function"
      ? sidebar(handleClose)
      : sidebar
  );


  React.useEffect(() => {

    if (fullscreen) return;

    clearTimeout(windowSizeTimer.current);

    const onResize = () => {
      windowSizeTimer.current = setTimeout(() => {
        const zoom = window.devicePixelRatio;
        if (zoom > 0 && zoom <= 2) setZoom(zoom);
        setResized(resized + 1);
      }, 100);
    }

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [])

  const updateHeight = (maxValue: number = 200) => {
    if (!ref?.current) return;
    const h = ref.current.clientHeight;
    const result = h < maxValue ? maxValue : h;

    const screenHeight = window.innerHeight;

    const maxSidebarHeight = result > screenHeight ? screenHeight - 120 : result * zoom - 120;

    setHeight(result * zoom + 120);
    setSidebarHeight(maxSidebarHeight);
  }

  React.useEffect(() => {
    if (fullscreen) return;
    clearTimeout(sizeTimer.current);
    sizeTimer.current = setTimeout(() => updateHeight(), 100);
  }, [children, ref, sidebar, childrenContent, resized]);

  const closeButton = (
    <div className="h-100 mb-auto d-flex flex-row align-items-center justify-content-end dialog-header">
      <Icon icon="x" invertThisColor="backgroundLighter" onClick={handleClose} size={45} />
    </div>
  );

  const sidebarHidden = !sidebar;

  return (
    <div className="position-fixed top-0 start-0 vh-100 vw-100 modal-form-container overflow-hidden">
      <div className="position-relative w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden">
        <div className="modal-form-background position-absolute top-0 start-0 w-100 h-100" onClick={() => handleClose(true)} />
        <div className={generateClassName("modal-form", {
          value: sidebarHidden,
          onTrue: "w-50",
          standard: "w-75 w-md-50"
        })} style={{ width: "fit-content", height: fullscreen ? "100vh" : `${height}px`, minHeight: "300px" }}>
          <div className="d-flex flex-sm-row gap-2 flex-column w-100 h-100 overflow-hidden">
            <div className="p-4 w-100 overflow-auto" >
              <div className="dialog-header d-flex flex-row align-items-center justify-content-between">
                <h1 className="text-wrap fw-bold m-0 p-0 text-uppercase text-start" style={{ color: "#002E7F", fontSize: "34px" }}>{title}</h1>
                <div className={generateClassName("d-flex flex-row align-items-start justify-content-end", {
                  value: sidebarHidden,
                  standard: "d-md-none"
                })}>
                  {closeButton}
                </div>
              </div>
              <div 
                className="modal-form-content gap-2 w-100 d-flex flex-column position-relative text-start" 
                ref={ref}
              >
                {childrenContent}
                {
                  !sidebarHidden && (
                    <div className="d-flex d-md-none w-100">
                      {sidebarContent}
                    </div>
                  )
                }
              </div>
            </div>
            {
              !sidebarHidden && (
                <div className="p-4 w-50 d-md-flex flex-column d-none justify-content-start" style={{ minWidth: "25%", backgroundColor: "var(--backgroundLighter)", minHeight: `${sidebarHeight - 20}px` }}>
                  <div className="d-md-flex d-none flex-row align-items-center justify-content-end">
                    {closeButton}
                  </div>
                  <div className="mt-auto h-100 w-100 d-flex flex-column align-items-end justify-content-end" style={{ backgroundColor: "var(--backgroundLighter)", overflowY: "auto" }}>
                    {sidebarContent}
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}