import React from "react";
import useTenantSettings from "../../hooks/useTenantSettings";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import Pill from "../pills/Pill";
import { TemplatePlaceholder } from "../../types/templatePlaceholder.schema";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";

export interface ITemplatePlaceholderActions {
  onAdd?: (placeholder: TemplatePlaceholder) => void,
  onRemove?: (placeholder: TemplatePlaceholder) => void
}

export interface ITemplatePlaceholderChipProps extends ITemplatePlaceholderActions {
  placeholder: TemplatePlaceholder,
  currentText?: string,
  readOnly?: boolean,
  saveText?: (newText: string) => void,
}

export default function TemplatePlaceholderChip({ currentText, readOnly, onAdd, onRemove, placeholder, saveText }: ITemplatePlaceholderChipProps) {

  const {
    getPlaceholderDisplayName
  } = useTemplateUtil();

  const isActive = currentText && currentText.includes(placeholder);

  const deleteFromText = () => {
    if (onRemove) onRemove(placeholder);
    if (!saveText) return;
    saveText("");
  }

  const addToText = () => {
    if (onAdd) onAdd(placeholder);
    if (!saveText) return;
    return saveText(placeholder);
  }

  return (
    <Flex row gap="1">
      <Pill
        color={isActive ? "success" : "background"}
        textColor={isActive ? "bright" : "primary"}
        text={getPlaceholderDisplayName(placeholder)}
        onClick={(!readOnly && (onAdd || saveText)) ? addToText : undefined}
      />
      {
        (!readOnly && isActive) && (
          <Button
            onClick={async () => deleteFromText()}
            icon="trash"
            variant="subtle"
            size="small"
            text="Zurücksetzen"
            color="error"
          />
        )
      }
    </Flex>
  )
}