import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes, IAppRoute } from "../../../config/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import { IAuthorizationOptions } from "../../../hooks/useAuthorized";
import { setActiveGroup, setActiveTab, setSidebarExpanded } from "../../../state/slices/sidebarSlice";
import { NavGroup, NavTab } from "../../../types/nav.types";
import WithPermissions from "../../permissions/WithPermissions";
import SideNavElement from "./SideNavElement";
import "./SideNavLink.css";
import { hexWithOpacity } from "../../../util/util";

interface ISideNavLinkProps extends IAuthorizationOptions {
  tab: NavTab,
  parentGroup?: NavGroup,
  externalLink?: boolean,
  openInNewTab?: boolean,
  to: IAppRoute | string,
  displayCondition?: boolean,
  icon?: string,
  text?: string,
  otherValidPaths?: IAppRoute[]
}

export default function SideNavLink({ externalLink, superAdminOnly, permissions, openInNewTab, to, icon, text, otherValidPaths, tab, parentGroup, displayCondition = true }: ISideNavLinkProps) {

  const {
    isExpanded,
    activeTab,
    activeGroup
  } = useAppSelector(state => state.sidebar);

  const [isActive, setIsActive] = React.useState<boolean>(activeTab === tab);
  const [hover, setHover] = React.useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const tabIsActive = activeTab === tab;
    setIsActive(tabIsActive);
    if (!tabIsActive) return;
  }, [activeTab]);

  React.useEffect(() => {

    const pathOptions = [];
    const linkPath = typeof to === "string" ? to : to.path;

    pathOptions.push(linkPath);
    if (!!otherValidPaths) pathOptions.push(...otherValidPaths.map(p => p.path));

    const isValid = !!pathOptions.find(p => !!matchPath(location.pathname, p));

    setIsActive(isValid);

    if (isValid) activateTab();

  }, [location]);

  const activateTab = () => {

    dispatch(setActiveTab(tab));

    if (activeGroup === parentGroup) return;

    if (!!parentGroup) dispatch(setActiveGroup(parentGroup));
    else dispatch(setActiveGroup(NavGroup.None));
  }

  const clickHandler = () => {
    dispatch(setSidebarExpanded(false));
    activateTab();

    const link = typeof to === "string" ? to : to.path;

    if (!externalLink) {
      navigate(link);
      return;
    }

    if (openInNewTab) {
      window.open(link, "_blank");
      return;
    }

    window.location.href = link;
  }

  const className = generateClassName("side-nav-link d-flex flex-row align-items-center w-100 position-relative", {
    value: isActive,
    onTrue: "side-nav-link-active"
  });

  if (!displayCondition) return null;

  const isInsideGroup = parentGroup !== undefined;

  const navElementProps = typeof to === "string" ? {} : to;

  const content = (
    <div
      style={generateStyle({
        name: "backgroundColor",
        value: hexWithOpacity("secondary", 0.2, true),
        applyCondition: (hover || isActive) && !isInsideGroup
      }, {
        name: "color",
        applyCondition: isInsideGroup,
        value: (hover || isActive) ? "white" : "var(--secondary)"
      })}
      className={className}
      onClick={clickHandler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <SideNavElement isInsideGroup={isInsideGroup} icon={icon} title={text} isActive={isActive} {...navElementProps} />
    </div>
  )

  if (!superAdminOnly && !permissions?.length) return content;

  return (
    <WithPermissions permissions={permissions} superAdminOnly={superAdminOnly}>
      {content}
    </WithPermissions>
  )
}