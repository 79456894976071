import React from "react";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import { IMailTemplateContent } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import ResponsiveText from "../text/ResponsiveText";
import Typography from "../text/Typography";
import { ITemplateElement } from "../../types/globalMailTemplates.schema";

export interface ITemplatePreviewProps {
  content: Array<ITemplateElement>,
  label: string,
  inline?: boolean
}

export default function TemplatePreview(props: ITemplatePreviewProps) {

  const {
    content,
    label,
    inline
  } = props;

  const {
    getStringified
  } = useTemplateUtil();

  if (!content || !content.length) return null;

  const value = getStringified(content, true);

  if (!value) return null;

  return (
    <Flex row={inline} gap="1">
      <Typography bold color="primary">{label}</Typography>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </Flex>
  )
}