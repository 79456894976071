import React from "react";
import { useGlobalMailTemplates } from "../../state/api/templates/useGlobalMailTemplates";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import TemplatePlaceholderChip from "./TemplatePlaceholderChip";
import UpdateGlobalMailTemplateButton from "./UpdateGlobalMailTemplateButton";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";
import Typography from "../text/Typography";
import Flex from "../container/Flex";
import TestMailTemplateButton from "./TestMailTemplateButton";
import WithPermissions from "../permissions/WithPermissions";
import TemplatePreview from "./TemplatePreview";

export default function GlobalMailTemplatesList() {
  const { getStringified } = useTemplateUtil();
  const { globalMailTemplates, loadingGlobalMailTemplates } = useGlobalMailTemplates();

  return (
    <WithPermissions permissions={["templates.mails.read"]}>
      {
        loadingGlobalMailTemplates
          ? <LoadingSpinner />
          : (
            <Table
              headers={[
                "Typ", "Verfügbare Platzhalter", "Status", "Aktionen"
              ]}
              items={globalMailTemplates || []}
              renderItem={m => (
                <TableRow key={m._id}>
                  <TableCell>
                    <Flex>
                      <MailTemplateTypeBanner template={m.templateType} />
                      <TemplatePreview content={m.subject} label="Betreff" />
                      <TemplatePreview content={m.body} label="Inhalt" />
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex row wrap>
                      {
                        m.availablePlaceholders && m.availablePlaceholders.length
                          ? m.availablePlaceholders.map(p => <TemplatePlaceholderChip key={p} placeholder={p} />)
                          : <em>Keine Platzhalter</em>
                      }
                    </Flex>
                  </TableCell>
                  <TableCell value={m.isPublished} labelOnFalse="Nicht veröffentlicht" labelOnTrue="In Verwendung" />
                  <TableCell>
                    <Flex>
                      <TestMailTemplateButton templateId={m._id} isGlobal />
                      <UpdateGlobalMailTemplateButton template={m} />
                    </Flex>
                  </TableCell>
                </TableRow>
              )}
            />
          )
      }
    </WithPermissions>
  )
}