import React, { ReactElement } from "react";
import Dialog, { IDialogBase } from "./Dialog";

interface IModalDialogProps extends IDialogBase {
  button: ReactElement,
  sidebar?: React.ReactNode | ((closeForm: () => void) => React.ReactNode),
  onOpen?: (abortController: AbortController) => Promise<any>,
  title: string,
  children: React.ReactNode | ((closeForm: () => void) => React.ReactNode),
  visible?: boolean,
  setVisible?: (visible: boolean) => void
}

export default function ModalDialog(props: IModalDialogProps) {

  const { onOpen, button } = props;

  const [visibleInternal, setVisibleInternal] = React.useState<boolean>(!!props.visible);

  const handleVisibleChange = (b: boolean) => {
    if (props?.setVisible) props.setVisible(b);
    else setVisibleInternal(b);
  }

  React.useEffect(() => {
    if (props.visible !== undefined) setVisibleInternal(props.visible);
  }, [props.visible]);

  React.useEffect(() => {
    if (!visibleInternal) return;
    if (!onOpen) return;

    const abortController = new AbortController();

    onOpen(abortController);

    return () => abortController.abort();
  }, [visibleInternal]);

  return (
    <>
      {
        React.cloneElement(button, { onClick: async () => handleVisibleChange(true) })
      }
      {
        visibleInternal && <Dialog {...props} setVisible={handleVisibleChange} />
      }
    </>
  )
}