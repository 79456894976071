import React from "react";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import { useAppSelector } from "../../hooks/reduxHooks";
import DocumentClearanceOverview from "../../components/documentClearance/DocumentClearanceOverview";
import useTenantSettings from "../../hooks/useTenantSettings";

export default function DocumentClearance() {
  const {
    titles
  } = useTenantSettings(true);

  return (
    <Page header={titles?.documentClearanceWord || Locale.titles.documentClearance}>
      <DocumentClearanceOverview />
    </Page>
  )
}